/// <reference path="../importer.ts" />

$(document).ready(() => {

    /**
     * XOR Decryption algorithm
     * @param {string} subject String you'd like to decrypt
     * @returns {string} Decrypted string
     * @constructor
     */
    const XORDecrypt = (subject: string): string => {
        if (!subject)
            return null;

        let decrypted: string = '';
        for (let i = 0; i < subject.length; i++) {
            let charCode = subject.charCodeAt(i);
            charCode = charCode ^ 128;
            decrypted += String.fromCharCode(charCode);
        }
        return decrypted;
    };

    /**
     * Parses all elements that have .encrypted-email-address class on them
     * and replaces the text() with decrypted value
     * Also parses the mailto links to link to the decrypted email address
     */
    const decryptEmailAddresses = (): void => {
        //First, find all email addresses that were encrypted and decrypt them
        $('.encrypted-email-address').each((index, element: Element) => {
            const el = $(element);
            const value = el.text();
            const decrypted = XORDecrypt(value);
            el.text(decrypted);
        });
        //Then , find links and add the right mailto attribute to them
        $('.mailto-link').each((index, elem: Element) => {
            const element = $(elem);
            const emailAddresss = element.find('span.encrypted-email-address').text();
            element.attr('href',`mailto:${emailAddresss}`);
        });
    };

    //Start the show!
    decryptEmailAddresses();

    try{
        let setLocationSpecificElements= (countryCode: string)=>{
            console.log('location set to ', countryCode);
            let addressesSelector = $('.localized.addresses');
            addressesSelector.find('.address').addClass('hidden');
            if (countryCode == 'US'){
                addressesSelector.find('.address[data-country="us"]').removeClass('hidden');
            } else {
                addressesSelector.find('.address[data-country="default"]').removeClass('hidden');
            }
        };
        if (localStorage.getItem('country')){
            setLocationSpecificElements(localStorage.getItem('country'));
        } else {
            $.ajax({
                url: '//freegeoip.net/json/',
                method: 'GET',
                success: ( data: any ) => {
                    if (data && data.country_code){
                        localStorage.setItem('country', data.country_code);
                        localStorage.setItem('geoIp', JSON.stringify(data));
                        setLocationSpecificElements(data.country_code);
                    }
                }
            });
        }
    } catch (ex){
        console.log('geoLocation or localStorage failed: ', ex);
    }

    let formSelector = $('.site-footer .form');
    let form = formSelector.submit((e: JQueryEventObject) => {
        e.preventDefault();
        let url = formSelector.attr('data-url');
        if (typeof  dataLayer != 'undefined')
            dataLayer.push({'event': 'mailingListSubscription'});
        $.get(`${url}&${form.serialize()}`)
            .then(() => {
                (<HTMLFormElement>form[0]).reset();
                $('.mailinglist-subscribe.success').removeClass('faded');
            })
            .fail(() => {
                (<HTMLFormElement>form[0]).reset();
                $('.mailinglist-subscribe.success').removeClass('faded');
                //$('.mailinglist-subscribe.fail').removeClass('faded');
                //request always fails
            });
    });
});

